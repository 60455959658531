import Swiper, { Navigation, Pagination, EffectFade, Autoplay } from 'swiper';
import marquee from 'vanilla-marquee'

Swiper.use([Navigation, Pagination, EffectFade, Autoplay]);

const swiper = new Swiper('.swiper', {
  slidesPerView: 1,
  autoplay: true,
  disableOnInteraction: false,
  spaceBetween: 16,
  loop: true,
  effect: "fade",
  fadeEffect: {
    crossFade: true
  },
  pagination: false,
  navigation: false
  /*pagination: {
    el: '.swiper-pagination',
    type: "fraction"
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  }*/
});

const heroSwiper = new Swiper('.hero-swiper', {
  pagination: false,
  navigation: false,
  effect: "fade",
  fadeEffect: {
    crossFade: true
  },
  autoplay: true,
  disableOnInteraction: false
});

const marqueeO = document.getElementsByClassName("marquee")[0];
if(marqueeO != undefined) {
  new marquee(marqueeO, {
    // recalcResize: true,
    startVisible: true,
    duplicated: true,
    duration: 0,
    speed: 50,
  });
}

const burger = document.getElementsByClassName("js-burger")[0];
function close_menu() {
  let target = document.getElementById('header');
  document.body.classList.toggle("locked");
}

burger.addEventListener("click", function (e) {
  close_menu();
});

const expandButtons = document.getElementsByClassName("js-expand");

for(var i = 0; i < expandButtons.length; i++)
{
  expandButtons[i].addEventListener("click", function (e) {
    var container = this.closest(".section");
    var target = container.getElementsByClassName("more")[0];
    target.classList.toggle("is-hidden");
    this.remove();
  });
}

window.onload = function get_projects() {
  const filterButtons = document.getElementsByClassName("js-filter");

  var searchParams = new URLSearchParams(window.location.search);

  if(searchParams.has("type") == true && searchParams.get("type") != "all") {
    var preFilter = searchParams.get("type");
    var targetProjects = document.querySelectorAll(`[data-tag="is-${preFilter}"]`);
    targetProjects.forEach(function(userItem) {
      filterList(userItem);
    });
  }

  for(var i = 0; i < filterButtons.length; i++)
  {
    filterButtons[i].addEventListener("click", function (e) {
      e.preventDefault();
      filterList(e.target);
    });
  }

  function filterList(button) {

    var selectedTag = button.getAttribute("data-tag");

    var container = button.closest(".section");
    var projects = container.querySelectorAll(".grid-teaser");
    var chips = container.querySelectorAll('.chip');

    chips.forEach(function(el) {
      el.classList.remove('is-active');
    });

    if(selectedTag == "all") {
      const url = new URL(window.location);
      url.searchParams.delete('type');
      window.history.pushState({}, '', url);

      projects.forEach(function(el) {
        el.classList.remove('is-hidden');
      });
    } else {
      const url = new URL(window.location);
      const searchParams = selectedTag.replace("is-","");
      url.searchParams.set('type', searchParams);
      window.history.pushState({}, '', url);

      projects.forEach(function(el) {
        el.classList.add('is-hidden');
      });
      var targetProjects = container.querySelectorAll(`.${selectedTag}`);
      targetProjects.forEach(function(el) {
        el.classList.remove('is-hidden');
      });
    }

    button.classList.toggle("is-active");
  }
};

let target = document.getElementById('header');
const sentinal = document.querySelector('.hero-slider')
if(target && sentinal){
  let callback = (entries, observer) => {
    if (!entries[0].isIntersecting) {
      target.classList.add('is-plain')
    } else {
      target.classList.remove('is-plain')
    }
  }
  let observer = new IntersectionObserver(callback);
  observer.observe(sentinal);
} else {
  target.classList.add('is-plain');
}



